<template>
  <div class="container">
    <div class="img-box f-sb-c">
      <img :src="bgUrl" class="bg-img" />
      <!-- <img src="~@/assets/invite_ti.png" class="ti-img" /> -->
      <!-- <img src="~@/assets/invite.png" class="img" /> -->
    </div>
    <van-form class="form-box mrow-30 pt-20" @submit="onSubmit">
      <van-field
        v-model="phone"
        name="手机号码"
        placeholder="手机号码"
        :rules="[
          { required: true, message: '请填写手机号码' },
          { validator, message: '请输入正确手机号码' },
        ]"
      />
      <van-field v-model="sms" center clearable placeholder="请输入短信验证码" :rules="[{ required: true, message: '请输入短信验证码' }]">
        <template #button>
          <van-button size="small" type="info" :disabled="!canSendSms" @click="handleShowModal">获取验证码</van-button>
        </template>
      </van-field>
      <div class="box-btn f-c-c">
        <van-button class="confirm-btn" block type="info" :disabled="!canSubmit" native-type="submit">立即加入</van-button>
      </div>
    </van-form>

    <van-dialog v-model="modal" title="图片验证" show-cancel-button @confirm="handleSendSms">
      <div class="f-c-c f-col">
        <img :src="info.base64Img" class="info-img" @click="loadCheckImg" />
        <van-field v-model="point" input-align="center" placeholder="请输入图片验证码" />
      </div>
    </van-dialog>

    <download-app :hidden="$route.query.hidden"/>
  </div>
</template>

<script>
import { getImgCode, getSMSCode, register } from '@/api/invite';
import { getItem } from '@/api/banner';
import DownloadApp from '@/components/DownloadApp.vue';

function validPhone(str) {
  return /^1\d{10}$/.test(str);
}

export default {
  name: 'InvitePage',

  components: {
    DownloadApp,
  },

  data: function () {
    return {
      bgUrl: '',
      invite: '',

      phone: '',
      sms: '',

      modal: false,
      info: {},
      point: '',
      loginState: false,
    };
  },

  computed: {
    canSendSms() {
      const phone = this.phone;
      return validPhone(phone);
    },
    canSubmit() {
      const sms = this.sms;
      return this.canSendSms && sms.length === 6;
    },
  },

  watch: {
    '$route.query': {
      handler(obj) {
        if (obj.invitationCode) {
          this.invite = obj.invitationCode;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.loadBg();
  },

  methods: {
    async loadBg() {
      const { success, msg, data } = await getItem({
        type: 3,
      });
      if (success) {
        const item = data.find((x) => x.type === '4'); // 邀请拉新 - 注册页
        if (item && 'imgUrl' in item) {
          this.bgUrl = item.imgUrl;
        }
      } else {
        this.$error(msg || '加载失败，请稍后重试~');
      }
    },
    validator(val) {
      return validPhone(val);
    },
    handleShowModal() {
      this.modal = true;
      this.loadCheckImg();
    },
    async loadCheckImg() {
      const { success, msg, data } = await getImgCode();
      if (success) {
        this.info = data;
      } else {
        this.$error(msg || '加载失败，请稍后重试~');
      }
    },
    async handleSendSms() {
      const { success, msg } = await getSMSCode({
        code: this.point,
        mobileNumber: this.phone,
        uuid: this.info.uuid,
      });
      if (success) {
        this.$success('短信已发出请稍等...');
        this.dialogVisible = false;
      } else {
        this.$error(msg);
      }
    },
    async onSubmit() {
      const { success, msg } = await register({
        mobileNumber: this.phone,
        smsCode: this.sms,
        invitationCode: this.invite,
      });
      if (success) {
        this.loginState = true;

        // TODO 后期增加新页面
        this.$success('注册成功！');
        setTimeout(() => {
          this.$router.push({
            path: `/h5/download`,
          });
        }, 1 * 1000);
      } else {
        this.$error(msg);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  // position: relative;
  width: 100vw;
  background-color: #fff;
  // z-index: -1;
  &:before {
    content: '';
    position: absolute;
    // z-index: -1;
    width: 100%;
    top: 0;
    height: 5.2rem;
  }
  .bg-img{
    width:100%;
  }
  .img-box {
    box-sizing: border-box;
    position: relative;
    z-index: 10;
    .ti-img {
      width: 2.6rem;
    }
    .img {
      width: 3.35rem;
    }
  }
  .form-box {
    position: relative;
    z-index: 10;
    min-height: 3.2rem;
    background-color: #fff;
    border-radius: 0.1rem;
    box-shadow: 0 0 0.2rem 0 #e8edfb;
    .box-btn {
      position: relative;
      top: 0.8rem;
      .confirm-btn {
        width: 5.8rem;
        border-radius: 0.18rem;
      }
    }
  }
}
.info-img {
  width: 2.6rem;
  height: 0.96rem;
}
</style>
